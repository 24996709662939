.textoConstruccion {
    font-family: 'Courier New', Courier, monospace;    
    color: white;
    width: 75vw;
}

@keyframes transparentToOpaque {
    from {
        opacity: 0%;
        }
    to { 
        opacity: 100%;
    }
}

body {
    margin: 0%;
    animation-name: transparentToOpaque;
    animation-duration: 1s;
}

.portadaGrupo {
    min-height: 40vh;
    width:100%;
    object-fit: cover;
}

.workablePage {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.workablePage2 {
    position: absolute;
    top: 5vh;
    background-color: black;
    width: 100%;
    min-height: 90vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.enConstruccion {
    display: flex;
    min-width: 100vw;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.imagenConstruccion {
    max-width: 75vw;
    height: fit-content;
}

@keyframes svgColorChange {
    from {
        color:#000000;
        fill: #000000;
        }
    to { 
        color:darkblue;
        fill: darkblue;
    }
}

@keyframes sidebarLoad {
    from {
        opacity: 0%;
        }
    to { 
        opacity: 100%;
    }
}

.personalCard_Sidebar {
    border-radius: 2rem;
    width: 100%;
    background-color: #bbbbbb;
    flex-direction: column;
    display: flex;
    animation-name: sidebarLoad;
    animation-duration: 1s;
    animation-fill-mode: both;
    font-family: "Rubik";
    align-items: center;
    padding-top: 5%;
    padding-bottom: 5%;
    max-width: 400px;
}

.personalCard_SidebarContainer {
    max-width: 80%;
    height: 80%;
    margin: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.personalCard_Image {
    width: auto;
    max-width: 50vw;
    max-height: 25vh;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
}

.personalCard_Profile {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.personalCard_Profile h1 {
    margin-bottom: 0%;
}

.personalCard_Profile h5 {
    margin-top: 0%;
    margin-left: 5%;
    color:rgb(123, 124, 184)
}

.personalCard_Description {
    width: 80%;
    text-align: center;
}

.personalCard_ButtonSection {
    padding-top: 3vh;
    padding-bottom: 4vh;
    width: 100%;
    display: flex;
}

.personalCard_Button1 {
    width: 80%;
    height: 8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5vh;

}

.personalCard_Button1:hover {
    width: 80%;
    height: 8vh;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5vh;
    animation-name: buttonHover;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.personalCard_Link a {
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-top: 1vh;
}

.personalCard_Link span {
    padding-left: 1vh;
}

.personalCard_Link a:hover {
    animation-name: svgColorChange;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.topMenu_Fixed {
    font-family: 'Rubik';
    position: fixed;
    top: 0px;
    min-width: 100vw;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    background-color: #c6c6c6;
    justify-content: center;
    align-items: center;
}

.topMenu_Fixed h1 {
    font-size: 100%;
    text-align: center;
}

.bottomMenu_Relative {
    font-family: 'Rubik';
    min-width: 100vw;
    width: 100%;
    background-color: gray;
}

.TopMenu_FlexContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
}

.TopMenu_Button {
    height: 50%;
    background-color: #454545;
    border-radius: 5rem;
    color:rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5rem;
    right: 1rem;
    width: 25vw;
}

.TopMenu_Button:hover {
    animation-name: menuButtonHover;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.TopMenu_Link {
    text-align: center;
    text-decoration: none;
    height: auto;
    width: 100%;
    color:white;
    vertical-align: middle;
}

.TopMenu_Link:visited {
    text-align: center;
    text-decoration: none;
    height: auto;
    width: 100%;
    color:white;
    vertical-align: middle;
}


.SocialMediaFlexContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
}

@keyframes menuButtonHover {
    to { 
        background-color:rgb(125, 125, 125);
    }
}

@keyframes svgColorChangeSML_Menu {
    to { 
        color:rgb(215, 90, 90);
        fill: rgb(215, 90, 90);
    }
}



.mainPageLogoContainer {
    width:80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    justify-content: space-around;
    max-height: 30vh;
    padding-bottom: 10px;
    padding-top: 10px;
}

.mainPageLogoImage {
    color: black;
    width: 95%;
}

.textoDisclaimerInicio {
    display: flex;
    font-family: "Rubik"; 
    width: 100%;
    justify-content: center;
}

.textoDisclaimerInicio h1 {
    font-size: 25px;
    width: 90%;
    text-align: center;
}

.TopMenu_TopMenu {
    display: flex;
    width: 100%;
    padding-top: 5%;
    background-color: gray;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

}

.TopMenu_TopMenuC {
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    background-color: gray;
    width: 100%;
    flex-direction: column;
    align-items: center;

}



.TopMenu_SocialMediaLink {
    height: 100%;
}

.TopMenu_SocialMediaLink a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
}


.TopMenu_SocialMediaLink a:hover {
    animation-name: svgColorChangeSML_Menu;
    animation-duration: 0.4s;
    animation-fill-mode: both;
}


@keyframes Menu_Link-ColorChange {
    to { 
        color:rgb(92, 36, 36);
    }
}

.TopMenu_Link {
    font-family: 'Rubik';
    color:rgb(0, 0, 0);
    width: auto;
}

.TopMenu_Link:visited {
    color:rgb(0, 0, 0)
}

.TopMenu_Link:hover{
    
    animation-name: Menu_Link-ColorChange;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    text-decoration: none;
}

.Eventos_ListaEventos {
    font-family: Rubik;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.Eventos_CartaEvento {
    width: 80%;
    background-color: lightgrey;
    padding: 5%;
    border-radius: 20px;
    margin-top: 5%;
}



.Eventos_SeccionBoletos {
    font-family: Rubik;

}

.Eventos_SeccionBoletos button {
    font-family: Rubik;
    width: 75%;
    background-color: rgb(192, 105, 105);
    padding: 5%;
    border-radius: 20px;
    border-style: none;
}

.Menu_CopyrightInfo {
    text-align: center;
    font-size: 10px;
}

.Fotos_Imagen {
    width: 100%;
    min-width: 375px;
}

.Fotos_Galeria {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.TopMenu_SocialMediaLinkIcon_Logo {
    height: 100%;
}

/* CLASE: SideMenu (SideMenu.js) */

.SideMenu_Background {
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: black;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
}

.SideMenu_Background_Hidden {
    animation-name: KF_SideMenuBG_ExitAnimation;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: both;
}

.SideMenu_Background_Shown {
    animation-name: KF_SideMenuBG_EnterAnimation;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: both;
}

.SideMenu_MenuContainer {
    font-family: Rubik;
    height: 100%;
    color: white;
    min-width: 225px;
    width: 25%;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: -100%;
    z-index: 3;
    align-items: center;
}

.SideMenu_MenuShape {
    background-color: black;
    rotate: 3deg;
    height: 150%;
    position: fixed;
    top: -10%;
    z-index: -1;
    width: 30vw;
    min-width: 325px;
}

.SideMenu_MenuContainer_Shown {
    animation-name: KF_SideMenu_EnterAnimation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    display: flex;
}

.SideMenu_MenuContainer_Hidden {
    animation-name: KF_SideMenu_ExitAnimation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    display: flex;
}

.SideMenu_ButtonContainer {
    display: flex;
    background-color: black;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: 10vh;

}

.SideMenu_Button {
    color: lightgray;
}

.SideMenu_LinkList {
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    height: 70%;
    padding-left: 15%;
    width: 100%;
}

.SideMenu_Link {
    padding-top: 5%;
    color: white;
    font-size: x-large;
    text-decoration: none;
    width: fit-content;
}

.SideMenu_Link:visited {
    text-decoration: none;
}

.SideMenu_Link:hover {
    text-decoration: none;
    animation-name: KF_SideMenu_LinkHover;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: both;
}



.SideMenu_SocialMediaLink {
    height: 100%;
}

.SideMenu_SocialMediaLink a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
}


.SideMenu_SocialMediaLink a:hover {
    animation-name: svgColorChangeSML_Menu;
    animation-duration: 0.4s;
    animation-fill-mode: both;
}

.SideMenu_SocialMediaContainer {
    padding-bottom: 10%;
    padding-left: 10%;
    width: 100%;
}

.SideMenu_SocialMediaFlexContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
}

.SideMenu_LogoContainer {
    width:80%;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    justify-content: space-around;
    padding-top: 10%;
}

.SideMenu_LogoImage {
    color: white;
    width: 100%;
}

.SideMenu_TopSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    
    flex-wrap: wrap;
}

.SideMenu_TopSection_LogoContainer {
    width: 70%;
    color: black;
    margin-top: 2%;
    margin-bottom: 2%;
}

@keyframes KF_SideMenu_EnterAnimation {
    from {
        left: -100%;
    }
    to {
        left: 0%;
    }
}

@keyframes KF_SideMenu_ExitAnimation {
    from {
        left: 0%;
    }
    to {
        left: -100%;
    }
}

@keyframes KF_SideMenu_LinkHover {
    to {
        color: salmon;
    }    
}

@keyframes KF_SideMenuBG_EnterAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
        visibility: visible;
    }    
}

@keyframes KF_SideMenuBG_ExitAnimation {
    from {
        opacity: 0.5;
    }
    to { 
        opacity: 0;
        visibility: hidden;
    }    
}

/* CLASE: Lanzamientos (Lanzamientos.js) */

.Lanzamientos_Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Lanzamientos_Nuevo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(193, 165, 174);
}

.Lanzamientos_ImagenReciente {
    display: flex;
    aspect-ratio: 100/20;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Lanzamientos_LogoReciente {
    width: 90%;
    animation-name: KF_Lanzamientos_AnimacionLogo;
    animation-duration: 0.5s;
    animation-direction: alternate;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

.Lanzamientos_VideoReciente {
    padding-top: 2%;
    padding-bottom: 2%;
}

.Lanzamientos_DetallesReciente {
    padding-top: 2%;
    padding-bottom: 2%;
    width: 80%;
    font-family: Rubik;
    font-size: medium;
}

.Lanzamientos_VideoPlayer {
    aspect-ratio: 16/9;
    border-radius: 15px;
    max-width: 80vw;
    width: 1000px;
    min-height: 30vh;
}

.Lanzamientos_Recientes {
    width: 100%;
    background-color: lightslategray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Lanzamientos_Recientes_Descripcion{
    
    font-family: Rubik;
    font-size: xx-large;
    text-align: center;
    margin-top: 2%;
    padding: auto;
    width: 85%;
}

.Lanzamientos_ListaRecientes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
}

.Lanzamientos_SpotifyReciente {
    padding: 2%;
}



@keyframes KF_Lanzamientos_AnimacionLogo {
    from {
        scale: 100%;
    }

    to {
        scale: 101%;
    }
}

/* HomePage */

.HomePage_Descripcion {
    font-family: 'Rubik';
    width: 80%;
    text-align: center;
    font-size: x-large;
    padding-bottom: 2%;
}

/* Sections */

.Sections_SectionTitle {
    font-family: Rubik;
    font-size: 300%;
    margin-bottom: 0;
}