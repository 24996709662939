@keyframes Rotation {
    from {
        rotate: -359deg;
    }
    to {
        rotate: 0deg;
    }
}

.Casetera_Container {
    margin: auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    max-width: 375px;
}

.Casetera_Division1 {
    background-color: black;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Casetera_Division2 {
    background-color: red;
    min-width: 60%;
}

.Casetera_Division3 {
    background-color: green;
    min-width: 5%;
}

.Casetera_Spotify {
    max-height: 100%;
}


.Casetera_Layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: gray;
    align-items: center;
}

.Casetera_OtherSection {
    height: 0%;
}

.Casetera_CasetteSection {
    height: 20%;
    display: flex;
    width: 80%;
   
}

.Casetera_CasetteRueda {
    height: 30%;
    animation-name: Rotation;
    animation-duration: 5s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 1, 1, 1);
}

.Casetera_CasetteRueda2 {
    height: 30%;
    animation-name: Rotation;
    animation-duration: 5s;
    animation-direction: reverse;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(1, 1, 1, 1);
}

.Casetera_ButtonSection {
    display: flex;
    flex-direction: row;
    height: 15%;
    width: 100%;
    justify-content: space-evenly;
}

.Casetera_Button {
    background-color: azure;
    border-radius: 0px 0px 25px 25px;
    width: 25%;
    max-height: 100%;
    border-color: black;
    border-width: 5px;
    border-style:outset;
    text-align: center;
}